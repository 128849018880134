<template>
  <section class="metrics" :key="selected_bot.id">
    <div class="row justify-content-between align-items-center b-bottom mb-4">
      <div class="label-box">
        <h3 class="mb-0">Métricas de {{ selected_bot.bot_name }}</h3>
        <div class="justify-content-center align-item-center">
          <select class="select_text mr-3 mb-0" v-model="filter">
            <option value="1">Hoy</option>
            <option value="2">Últimos 7 días</option>
            <option value="3">Últimos 30 días</option>
          </select>
          <template v-if="filtered_items?.length > 0">
            <span
              v-for="(item, index) in filtered_items"
              @click="selected_item = item"
              :class="{
                'btn-select bg_grey desktop mr-3 mb-0': true,
                selected: selected_item.name == item.name
              }"
              :key="index"
              >{{ item.name }}</span>
          </template>
          <select class="select_text mobile mb-0" v-model="selected_item">
            <option v-for="(item, index) in filtered_items" :value="item">{{ item.name }}</option>
          </select>
        </div>
      </div>
    </div>
    <div class="col">
      <FadeTransition :duration="200" v-show="selected_item.name == 'Conversaciones y leads' || selected_item.name == 'Todo'" :key="'1' + filter">
        <div>
          <h3 v-if="selected_item.name == 'Todo'" class="pl-3 my-5" for="">Conversaciones y leads</h3>
          <div class="row">
            <div class="col-xl-6 mb-4">
              <div v-show="no_data.includes('n1')" class="no_data-card"><span class="mb-0">Sin datos</span></div>
              <div v-show="!no_data.includes('n1')" class="card">
                <div class="chart-item card-body pb-0" id="n1" title="Conversaciones"></div>
              </div>
            </div>
            <div class="col-xl-6 mb-4">
              <div v-show="no_data.includes('n2')" class="no_data-card"><span class="mb-0">Sin datos</span></div>
              <div v-show="!no_data.includes('n2')" class="card">
                <div class="chart-item card-body pb-0" id="n2" title="Leads"></div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-4 mb-4">
              <div v-show="no_data.includes('n8')" class="no_data-card"><span class="mb-0">Sin datos</span></div>
              <div v-show="!no_data.includes('n8')" class="card p-5">
                <h4 class="mb-4" for="">Conversaciones resueltas por el chatbot</h4>
                <div class="row">
                  <div class="col-xl-6 pl-0 mb-2">
                    <p class="mb-0"><span class="number_box">{{ resolved_by_bot[1]?.value }}</span> {{ resolved_by_bot[1]?.name }}</p>
                  </div>
                  <div class="col-xl-6 pl-0 mb-2">
                    <p class="mb-0"><span class="number_box">{{ resolved_by_bot[0]?.value }}</span> {{ resolved_by_bot[0]?.name }}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-xl-4 mb-4">
              <div v-show="no_data.includes('n13')" class="no_data-card"><span class="mb-0">Sin datos</span></div>
              <div v-show="!no_data.includes('n13')" class="card p-5">
                <h4 class="mb-4" for="">Leads fuera de horario de atención</h4>
                <div>
                  <p class="mb-0"><span class="number_box">{{ leads_out_of_time[0]?.value }}</span> Leads este periodo.</p>
                </div>
              </div>
            </div>
            <div class="col-xl-4 mb-4">
              <div class="no_data-card" v-show="no_data.includes('card-n2')">Sin datos</div>
              <div class="card p-4 px-5" v-show="!no_data.includes('card-n2')">
                <h4 class="d-block mb-3">Conteo de calificaciones</h4>
                <div class="row emojis_box">
                  <div v-for="(value, key) in count_califications" :key="key">
                    <div class="d-flex flex-column align-items-center justify-content-center mr-3">
                      <img class="icon-emoji mb-3" :src="emojis[key-1].url" alt="">
                      <p class="text_sm negrita mb-2">{{ emojis[key-1].label }}</p>
                      <p class="text_sm number_box negrita mx-0 mb-0 py-1 d-block">{{ value }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col mb-4">
            <div v-show="no_data.includes('n3')" class="no_data-card"><span class="mb-0">Sin datos</span></div>
            <div v-show="!no_data.includes('n3')" class="card">
              <div class="chart-item card-body pb-0" id="n3" title="Días con más interacciones"></div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6 mb-4">
              <div v-show="no_data.includes('n12')" class="no_data-card"><span class="mb-0">Sin datos</span></div>
              <div v-show="!no_data.includes('n12')" class="card p-5">
                <h4 class="mb-4">Conversaciones y horario de atención</h4>
                <div class="info_box float_box">
                  <div class="mb-3" v-for="(item, index) in schedules" :key="index">
                    <p class="text_sm mr-2" v-for="(day, index) in item.days" :key="index">{{ getNameDay(day) }}</p>
                    <span class="text_sm normal negrita mr-2">{{ item.range }} hrs.</span>
                  </div>
                  <span v-if="default_hour" class="text_xs mb-0">(Horario por defecto)</span>
                </div>
                <div class="chart-item" id="n12"></div>
              </div>
            </div>
            <div class="col-xl-6 mb-4">
              <div v-show="no_data.includes('n9')" class="no_data-card"><span class="mb-0">Sin datos</span></div>
              <div v-show="!no_data.includes('n9')" class="card">
                <div class="chart-item card-body pb-0" id="n9"></div>
              </div>
            </div>
          </div>
        </div>
      </FadeTransition>
      <FadeTransition :duration="200" v-show="selected_item.name == 'Canales' || selected_item.name == 'Todo'" :key="'1.2' + filter">
        <div>
          <h3 v-if="selected_item.name == 'Todo'" class="pl-3 my-5" for="">Canales</h3>
          <div class="row">
            <div class="col-xl-6 mb-4">
              <div v-show="no_data.includes('n10')" class="no_data-card"><span class="mb-0">Sin datos</span></div>
              <div v-show="!no_data.includes('n10')" class="card">
                <div class="chart-item card-body pb-0" id="n10"></div>
              </div>
            </div>
            <div class="col-xl-6 mb-4">
              <div v-show="no_data.includes('n11')" class="no_data-card"><span class="mb-0">Sin datos</span></div>
              <div v-show="!no_data.includes('n11')" class="card">
                <div class="chart-item card-body pb-0" id="n11"></div>
              </div>
            </div>
          </div>
        </div>
      </FadeTransition>
      <FadeTransition :duration="200" v-show="selected_item.name == 'Intenciones frecuentes' || selected_item.name == 'Todo'" :key="'2' + filter">
        <div>
          <h3 v-if="selected_item.name == 'Todo'" class="pl-3 my-5" for="">Intenciones frecuentes</h3>
          <div class="col mb-4">
            <div v-show="no_data.includes('n4')" class="no_data-card"><span class="mb-0">Sin datos</span></div>
            <div v-show="!no_data.includes('n4')" class="card">
              <div class="chart-item card-body" id="n4"></div>
            </div>
          </div>
        </div>
      </FadeTransition>
      <FadeTransition :duration="200" v-show="selected_item.name == 'Intervenciones de agente' || selected_item.name == 'Todo'" :key="'3' + filter">
        <div>
          <h3 v-if="selected_item.name == 'Todo'" class="pl-3 my-5" for="">Intervenciones de agente</h3>
          <div class="col mb-4">
            <div v-show="no_data.includes('n5')" class="no_data-card"><span class="mb-0">Sin datos</span></div>
            <div v-show="!no_data.includes('n5')" class="card">
              <div class="chart-item card-body pb-0" id="n5" title="Intervenciones de agente"></div>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-6 mb-4">
              <div v-show="no_data.includes('card-n3')" class="no_data-card">Sin datos</div>
              <div v-show="!no_data.includes('card-n3')" class="card p-5">
                <p class="mb-0"><span v-if="average_intervention?.hours > 0">{{ average_intervention?.hours == 1 ? 'hora' : 'horas' }}</span> {{ average_intervention?.minutes == 1 ? 'minuto' : 'minutos' }} y {{ average_intervention?.seconds == 1 ? 'segundo' : 'segundos' }}</p>
              </div>
            </div>
            <div class="col-xl-6 mb-4">
              <div v-show="no_data.includes('card-n4')" class="no_data-card">Sin datos</div>
              <div v-show="!no_data.includes('card-n4')" class="card p-5">
                <p class="mb-0"><span v-if="average_without_agent?.hours > 0">{{ average_without_agent?.hours == 1 ? 'hora' : 'horas' }}</span> {{ average_without_agent?.minutes == 1 ? 'minuto' : 'minutos' }} y {{ average_without_agent?.seconds == 1 ? 'segundo' : 'segundos' }}</p>
              </div>
            </div>
          </div>
        </div>
      </FadeTransition>
      <FadeTransition :duration="200" v-show="selected_item.name == 'eCommerce' || selected_item.name == 'Todo'" :key="'4' + filter">
        <div v-if="providers?.length > 0 || selected_bot.id == 537 || selected_bot.id == 64">
          <h3 v-if="selected_item.name == 'Todo'" class="pl-3 my-5" for="">eCommerce</h3>
          <DashboardEcommerce :metrics="true" :total_payd_money="totalPayMoney" />
          <div class="row mt-4">
            <div class="col-xl-6 mb-4">
              <div v-show="no_data.includes('n6')" class="no_data-card"><span class="mb-0">Sin datos</span></div>
              <div v-show="!no_data.includes('n6')" class="card bg_grey">
                <div class="chart-item card-body" id="n6"></div>
              </div>
            </div>
            <div class="col-xl-6 mb-4">
              <div class="no_data-card" v-show="no_data.includes('card-n1')">Sin datos</div>
              <div v-show="!no_data.includes('card-n1')" :key="'5'+ filter" class="table">
                <div class="tb-head mb-2 p-3">
                  <div class="col-8 px-0">
                    <h4 class="mb-0">Productos más vendidos</h4>
                  </div>
                  <div class="col-2">
                    <span>Precio</span>
                  </div>
                  <div class="col-2 px-0">
                    <span>Cantidad</span>
                  </div>
                </div>
                <div class="tb-body">
                  <div class="row_box mb-2" v-for="(product, index) in productsPaid" :key="index">
                    <div class="row px-3 mb-2">
                      <div class="col-8 row align-items-center px-0">
                        <span class="table-item-index t-regular mr-2">{{ (index + 1) + '. ' }}</span>
                        <img class="product-img mr-2" :src="product.url_image" alt="">
                        <p class="capitalize t-ellipsis mb-0">{{ product.producto }}</p>
                      </div>
                      <div class="col-2 row align-items-center">
                        <p class="mb-0">$ {{ product.price }}</p>
                      </div>
                      <div class="col-2 row align-items-center px-0">
                        <p class="mb-0">{{ product.cantidad }} un.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col mb-4">
            <div v-show="no_data.includes('n7')" class="no_data-card"><span class="mb-0">Sin datos</span></div>
            <div v-show="!no_data.includes('n7')" class="card">
              <div class="chart-item card-body" id="n7"></div>
            </div>
          </div>
        </div>
      </FadeTransition>
    </div>
  </section>
</template>

<script>
import { mapState } from "vuex";
import LineChart from './charts/LineChart';
import BarChart from './charts/BarChart';
import QuestionBarChart from './charts/QuestionsBarChart';
import donutChart from './charts/donutChart';
import pieChart from './charts/pieChart';
import enviroment from '@/environment';
import axios from 'axios';
import { FadeTransition } from "vue2-transitions";
import DashboardEcommerce from "@/components/DashboardEcommerce";
import dashboard_api from '@/dashboard_api.js';

export default {
  name: 'metrics',
  components: {
    FadeTransition,
    DashboardEcommerce
  },
  mixins: [
    LineChart,
    BarChart,
    QuestionBarChart,
    donutChart,
    pieChart
  ],
  data() {
    return {
      maintenance: true,
      dataReady: false,
      filter: 3,
      conversations: {},
      productsPaid: [],
      count_califications: {},
      average_intervention: {},
      average_without_agent: {},
      data_card: {},
      items: [
        { name: 'Conversaciones y leads' },
        { name: 'Canales' },
        { name: 'Intenciones frecuentes' },
        { name: 'Intervenciones de agente' },
        { name: 'eCommerce' },
        { name: 'Todo' }
      ],
      emojis: [
        { url: '/img/icons/rating/1.1-bad.svg', label: 'Malo' },
        { url: '/img/icons/rating/2.1-meh.svg', label: 'Regular' },
        { url: '/img/icons/rating/3.1-what.svg', label: 'Útil' },
        { url: '/img/icons/rating/4.1-nice.svg', label: 'Bueno' },
        { url: '/img/icons/rating/5.1-love.svg', label: 'Buenísimo' }
      ],
      selected_item: {
        name: 'Conversaciones y leads'
      },
      no_data: [],
      totalPayMoney: [],
      filtered_items: [],
      resolved_by_bot: [],
      leads_out_of_time: [],
      schedules: [],
      days: [],
      default_hour: false
    };
  },
  async mounted() {
    // Revisar necesario para traer la data al cambiar la vista
    this.filterEcommerce();
    this.getSchedule();

    if (this.maintenance) {
      this.$router.push({path: "/maintenance", query: { origin: this.$route.name }});
    }

  },
  computed: {
    ...mapState(["selected_bot", "providers"]),

    showModuleChart() {
      return this.items.some(el => el.name == this.selected_item.name || this.selected_item.name == 'Todos');
    },
  },
  watch: {
    selected_bot(val) {
      this.getSchedule();
    },
    providers(val) {
      // Necesario para traer la data en la misma vista
      if (val?.length > 0) {
        this.filterEcommerce()
      };
    },
    selected_item: {
      immediate: true,
      handler(val) {
        this.getSectionsCarts(val);
      }
    },
    filter(val) {
      this.getSectionsCarts();
    }
  },
  methods: {
    getNameDay(number) {
      switch (number) {
        case 1: return "Lunes";
        case 2: return "Martes";
        case 3: return "Miércoles";
        case 4: return "Jueves";
        case 5: return "Viernes";
        case 6: return "Sábado";
        case 7: return "Domingo";
      }
    },
    async getSchedule() {
      this.schedules = (await dashboard_api.get(`schedule/list/${this.selected_bot.id}`)).data;

      if (this.schedules.length == 0) {
        this.default_hour = true;
        this.schedules.push({
          days: [1, 2, 3, 4, 5],
          range: "08:00-18:00",
          bot_id: this.selected_bot.id,
          timezone: "America/Santiago"
        });
      } else {
        this.default_hour = false;
      }
      let sorted_days = this.schedules[0].days.sort();
      this.days = sorted_days;
    },
    async filterEcommerce() {
      let ids = [537, 67];
      let ecommerces = ['vtex', 'shopify', 'woocommerce', 'jumpseller']
      if (this.providers.length > 0) {
        let provider = this.providers.find(el => ecommerces.includes(el.provider));
        console.log("provider-------->", provider?.provider);
  
        if (provider || ids.includes(this.selected_bot.id)) return this.filtered_items = this.items;
      } else return this.filtered_items = this.items.filter(el => el.name != 'eCommerce');
    },
    getSectionsCarts(val) {
      this.no_data = [];
      let value = val?.name ? val : this.selected_item;

      switch (value.name) {
        case 'Conversaciones y leads':
          Promise.all([
            this.getConversations(),
            this.getLeads(),
            this.getCountCalifications(),
            this.getCalificationsAverage(),
            this.getInteractions(),
            this.getConversationsResolvedByBot(),
            this.getConversationsOutTime(), // info card
            this.getLeadOutTime() // info card
          ]);
          break;
        case 'Canales':
          new Promise((resolve, reject) => {
            this.getConversatiosByChannel();
          });
          break;
        case 'Intenciones frecuentes':
          new Promise((resolve, reject) => {
            this.getIntentions();
          });
          break;
        case 'Intervenciones de agente':
          new Promise((resolve, reject) => {
            this.getInterventions(),
            this.getAverageIntervention() // info card
            this.getDelayInResponse() // info card
          });
          break;
        case 'eCommerce':
          Promise.all([
            this.getProductFrequentlyInCart(),
            this.getProductPaid(),
            this.getMoneyCart(),
          ]);
          break;
        case 'Todo':
          this.getAllCharts();
          break;
        default:
          return [];
      }
    },
    getAllCharts() {
      Promise.all([
        // Conversaciones y lead
        this.getConversations(),
        this.getLeads(),
        this.getCalificationsAverage(),
        this.getCountCalifications(),
        this.getInteractions(),
        this.getConversationsResolvedByBot(),
        this.getConversationsOutTime(),
        this.getLeadOutTime(),
        // Canales
        this.getConversatiosByChannel(),
        // Intenciones frecuentes
        this.getIntentions(),
        // Intervenciones de agente
        this.getInterventions(),
        this.getAverageIntervention(),
        this.getDelayInResponse(),
        // Producto más agregado
        this.getProductFrequentlyInCart(),
        this.getProductPaid(),
        this.getMoneyCart()
      ]).then(resp => {
        console.log("name->", this.selected_item.name);
      });
    },
    getConversations() {
      axios
        .post(`${enviroment.url_metrics}/conversation_metrics/conversations`, {
          bot_id: this.selected_bot.id,
          filter: this.filter,
          chart: 'conversaciones'
        })
        .then(resp => {
          if (resp.data.status != false) this.barChart(resp.data, 'n1', 'Conversaciones');
          else this.no_data.push('n1');
        })
        .catch(err => {
          this.no_data.push('n1');
          console.log("errro", err);
        });
    },
    getLeads() {
      axios
        .post(`${enviroment.url_metrics}/lead_metrics/count_leads`, {
          bot_id: this.selected_bot.id,
          filter: this.filter,
          chart: 'leads'
        })
        .then(resp => {
          if (resp.data.status != false) this.barChart(resp.data, 'n2', 'Leads');
          else this.no_data.push('n2');
        })
        .catch(err => {
          this.no_data.push('n2');
          console.log("errro", err);
        });
    },
    getCountCalifications() {
      axios
        .post(`${enviroment.url_metrics}/conversation_metrics/count_califications`, {
          bot_id: this.selected_bot.id,
          filter: this.filter,
          chart: 'count_califications'
        })
        .then(resp => {
          if (resp.data.status != false) this.count_califications = resp.data;
          else this.no_data.push('card-n2');
        })
        .catch(err => {
          this.no_data.push('card-n2');
          console.log("errro", err);
        });
    },
    getCalificationsAverage() {
      axios
        .post(`${enviroment.url_metrics}/conversation_metrics/califications_received`, {
          bot_id: this.selected_bot.id,
          filter: this.filter,
          chart: 'califications_average'
        })
        .then(resp => {
          if (resp.data.status != false) this.barChart(resp.data, 'n9', 'Calificaciones promedio');
          else this.no_data.push('n9');
        })
        .catch(err => {
          this.no_data.push('n9');
          console.log("errro", err);
        });
    },
    getConversatiosByChannel() {
      axios
        .post(`${enviroment.url_metrics}/conversation_metrics/by_channel`, {
          bot_id: this.selected_bot.id,
          filter: this.filter,
          chart: 'conversations_by_channel'
        })
        .then(resp => {
          if (resp.data.status != false) {
            const actualDataChart = resp.data[0].actual_data.map((item) => {
              return {
                value: item.cantidad,
                name: item.canal,
              };
            });
            const oldDataChart = resp.data[1].last_data.map((item) => {
              return {
                value: item.cantidad,
                name: item.canal,
              };
            });
            this.donutChart('n10', 'Canales actuales', 'Conversaciones del periodo actual', actualDataChart);
            this.donutChart('n11', 'Canales anteriores', 'Conversaciones del periodo anterior', oldDataChart);
          } else {
            this.no_data.push('n10');
            this.no_data.push('n11');
          };
        })
        .catch(err => {
          this.no_data.push('n10');
          this.no_data.push('n11');
          console.log("errror_pie", err);
        });
    },
    getInteractions() {
      axios.post(`${enviroment.url_metrics}/conversation_metrics/interactions`, {
        bot_id: this.selected_bot.id,
        filter: this.filter,
        chart: 'interactions'
      })
      .then(resp => {
        if (resp.data.status != false) this.questionBarChart(resp.data, 'n3', 'Días con más interacciones');
        else this.no_data.push('n3');
      }).catch(err => {
        this.no_data.push('n3');
        console.log("errro", err);
      });
    },
    getIntentions() {
      axios
        .post(`${enviroment.url_metrics}/lead_metrics/intentions`, {
          bot_id: this.selected_bot.id,
          filter: this.filter,
          chart: 'intentions'
        })
        .then(resp => {
          if (resp.data.status != false) this.questionBarChart(resp.data, 'n4', 'Intenciones frecuentes');
          else this.no_data.push('n4');
        }).catch(err => {
          this.no_data.push('n4');
          console.log("errro", err);
        });
    },
    getInterventions() {
      axios.post(`${enviroment.url_metrics}/agent_metrics/interventions`, {
        bot_id: this.selected_bot.id,
        filter: this.filter,
        chart: 'interventions'
      })
      .then(resp => {
        if (resp.data.status != false) this.questionBarChart(resp.data, 'n5', 'Intervenciones de agente');
        else this.no_data.push('n5');
      }).catch(err => {
        this.no_data.push('n5');
        console.log("errro", err);
      });
    },
    getProductFrequentlyInCart() {
      axios.post(`${enviroment.url_metrics}/cart_metrics/frequently_products_in_cart`, {
        bot_id: this.selected_bot.id,
        filter: this.filter,
        chart: 'frequently_in_cart'
      }).then(resp => {
        if (resp.data.length > 0) {
          const dataChart = resp.data.map((item) => {
            return {
              value: item.cantidad,
              name: item.producto,
            };
          });
          this.donutChart('n6', 'Productos más agregados', '', dataChart);
        } else this.no_data.push('n6');
      }).catch(err => {
        this.no_data.push('n6');
        console.log("errro", err);
      });
    },
    getMoneyCart() {
      axios.post(`${enviroment.url_metrics}/cart_metrics/money_in_cart`, {
        bot_id: this.selected_bot.id,
        filter: this.filter,
        chart: 'money_cart'
      }).then(resp => {
        if (resp.data.status != false) {
          this.barChart(resp.data, 'n7', 'Ventas desde el chatbot');
          this.totalPayMoney = resp.data.data;
        } else this.no_data.push('n7');
      }).catch(err => {
        this.no_data.push('n7');
        console.log("errro", err);
      });
    },
    getConversationsResolvedByBot() {
      axios
        .post(`${enviroment.url_metrics}/bot_metrics/conversations_resolved`, {
          bot_id: this.selected_bot.id,
          filter: this.filter,
          chart: 'conversations_resolved'
        })
        .then(resp => {
          if (resp.data.status != false) {
            this.resolved_by_bot = [
              {
                value: resp.data.data['periodo anterior'],
                name: resp.data.dimensions[1],
              },
              {
                value: resp.data.data['últimos 30 días'],
                name: resp.data.dimensions[2]
              }
            ];
          } else this.no_data.push('n8');
        })
        .catch((err => {
          this.no_data.push('n8')
          console.log("getConversationsResolvedByBot err->", err)}
        ));
    },
    getProductPaid() {
      axios
        .post(`${enviroment.url_metrics}/cart_metrics/frequently_products_paid`, {
          bot_id: this.selected_bot.id,
          filter: this.filter,
          chart: 'products_paid'
        })
        .then(resp => {
          if (resp.data.status != false) this.productsPaid = resp.data;
          else this.no_data.push('card-n1');
        }).catch(err => {
          this.no_data.push('card-n1');
          console.log("errro", err);
        });
    },
    getConversationsOutTime() {
      axios
        .post(`${enviroment.url_metrics}/conversation_metrics/out_of_time`, {
          bot_id: this.selected_bot.id,
          filter: this.filter,
          chart: 'conversations_out_time'
        })
        .then(resp => {
          console.log("resp.data->", resp.data);
          if (resp.data.status != false) {
            let out_of_time = [
              { name: 'Dentro de horario', value: resp.data[1]?.value },
              { name: 'Fuera de horario', value: resp.data[0]?.value }
            ]
            this.pieChart('n12', '', out_of_time);
          } else this.no_data.push('n12');
        }).catch(err => {
          this.no_data.push('n12');
          console.log("errro", err);
        });
    },
    getLeadOutTime() {
      axios
        .post(`${enviroment.url_metrics}/lead_metrics/leads_out_time`, {
          bot_id: this.selected_bot.id,
          filter: this.filter,
          chart: 'leads_out_time'
        })
        .then(resp => {
          if (resp.data.status != false) {
            this.leads_out_of_time = resp.data;
            // this.pieChart('n13', 'Leads fuera de horario de atención', resp.data)
          } else this.no_data.push('n13');
        }).catch(err => {
          this.no_data.push('n13');
          console.log("errro", err);
        });
    },
    getAverageIntervention() {
      axios
        .post(`${enviroment.url_metrics}/conversation_metrics/average_with_agent`, {
          bot_id: this.selected_bot.id,
          filter: this.filter,
          chart: 'average_intervention'
        })
        .then(resp => {
          if (resp.data.status != false) this.average_intervention = resp.data;
          else this.no_data.push('card-n3');
        }).catch(err => {
          this.no_data.push('card-n3');
          console.log("errro", err);
        });
    },
    getDelayInResponse() {
      axios
        .post(`${enviroment.url_metrics}/conversation_metrics/average_without_agent`, {
          bot_id: this.selected_bot.id,
          filter: this.filter,
          chart: 'average_without_agent'
        })
        .then(resp => {
          if (resp.data.status != false) this.average_without_agent = resp.data;
          else this.no_data.push('card-n4');
        }).catch(err => {
          this.no_data.push('card-n4');
          console.log("errro", err);
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.metrics {
  .card {
    position: relative;
    padding: 1rem 1rem 0;

    .chart-item {
      width: 100%;
      height: 300px;
    }
    .card {
      border-radius: .75rem;
      height: 100%;
    }
  }
  .product-img {
    width: 45px;
    min-height: 45px;
    height: 45px;
    border-radius: .25rem;
  }
  .table {
    border: 1px solid #e8e8e8;
    background-color: #fff;
    border-radius: 1rem;
    overflow-y: hidden;
    max-height: 318px;
    
    .tb-head {
      border-radius: .5rem .5rem 0 0;
      display: flex;
      background-color: #DEEDFF;

      span {
        color: #181818;
        font-weight: 700;
      }
    }
    .tb-body {
      overflow-y: scroll;
      max-height: calc(318px - 54.5px);
    }
    .row_box:nth-child(even) {
      background-color:#fafafa;
    }
  }
  .b-bottom {
    padding: 1.5rem 2rem;
    border-bottom: 1px solid #e8e8e8;
  }
  .selected {
    border: 1px solid #2981EF;
    color: #2981EF;
  }
  .no_data-card {
    background-color: #fafafa;
    color: #767676;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    height: 300px;
    border: 1px solid #e8e8e8;
    border-radius: 1rem;
  }
  .emojis_box {
    min-height: 30px;

    .icon-emoji {
      height: 30px;
    }
  }
  .table-item-index {
    width: 1rem;
    min-width: 1rem;
    max-width: 25px;
  }
  .mobile {
    @media (min-width: 1419px) {
      display: none;
    }
  }
  .desktop {
    @media (max-width: 1420px) {
      display: none;
    }
  }
  .float_box {
    position: absolute;
    top: 2rem;
    right: 2rem;
    
    @media (max-width: 480px) {
      position: relative;
      width: 100%;
      margin: 0 2rem 3rem;
    }
    div:last-child {
      margin-bottom: 0 !important;
    }
  }
}
</style>
